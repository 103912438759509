.App {
  text-align: center;
}

.inner {
  display: flex;
  justify-content: space-around;
  font-family: sans-serif;
  text-align: center;
  padding-top: 40px;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 7.5%;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.3);
  background-color: rgba(192,192,192, 0.8);
  border-radius: 25px;
}

.time {
  font-size: 42px;
}